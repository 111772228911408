/*
 * @Author: yanminw 2398988384@qq.com
 * @Date: 2025-01-17 14:03:54
 * @LastEditors: yanminw 2398988384@qq.com
 * @LastEditTime: 2025-01-17 15:08:44
 * @FilePath: \yf_car\src\utils\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios'
import {getToken} from '../utils/auth'
import {Dialog} from 'vant'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 30000,
})
// request拦截器
service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers['token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config
  },
  error => {
    console.log('请求前的错误：' + error)
    return Promise.reject(error)
  },
)

// 响应拦截器
service.interceptors.response.use(
  async res => {
    let responseData = res.data
    const {code, msg} = responseData
    if (code === 1) return responseData
    // Dialog({
    //   message: msg,
    // })
    return Promise.reject(msg)
  },
  async error => {
    let {message} = error
    if (message == 'Network Error') {
      message = '网络请求异常，请检查手机网络后重试'
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时'
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常'
    }
    return Promise.reject(error)
  },
)
export default service
