/*
 * @Description:
 * @Author: Dwyanewang
 * @Date: 2020-07-29 18:43:07
 * @LastEditors: yanminw 2398988384@qq.com
 * @LastEditTime: 2025-01-17 14:21:54
 */

// import { request } from './axios.config'
// const path = require('path')
// const files = require.context('./modules', true, /\.js$/)
// const generators = files.keys().map(key => ({
//     name: path.basename(key, '.js'),
//     func: files(key).default
// }))

// export default Object.assign({},
//     ...generators.map(generator => ({
//         [generator.name]: generator.func(request)
//     }))
// )
import request from '@/utils/request'

/**
 * @description 跳转到三方授权登录地址
 * @param {Number} passageway_id 通道id
 * @param {Number} consume_id 停车单id
 * @param {Number} smartpark_id 园区id
 * @param {string} from 三方类型（wx/ali 微信/支付宝）
 * @param {string} h5_url 登录后跳回页面（跳回时 url 上 带了 token）
 * @returns 返回token信息
 */
export function redirectOauth(params) {
  return request({
    url: '/v1.oauth/redirectOauth',
    method: 'get',
    params,
  })
}

/**
 * @description 获取当前已登录车主信息
 * @returns 获取用户信息
 */
export function getCarOwnerInfo(data) {
  return request({
    url: '/v1.pub/getCarOwnerInfo',
    method: 'post',
    data,
  })
}

/**
 * @description 获取 停车场的 物业电话号码
 * @param {Number} passageway_id 通道id
 * @param {Number} consume_id 停车场id
 * @param {Number} smartpark_id 园区id
 * @param {string} consume_id 停车订单id
 * @returns 返回停车场的 物业电话号码
 */
export function getPhone(data) {
  return request({
    url: '/v1.pub/getPhone',
    method: 'post',
    data,
  })
}

// 获取车辆的全部优惠券
export function getCarCouponListOnOrders(data) {
  return request({
    url: '/v1.orders/getCarCouponListOnOrders',
    method: 'post',
    data,
  })
}
