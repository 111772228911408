<!--
 * @Author: yanmin
 * @Description: 
 * @Date: 2022-04-14 18:14:30
 * @LastEditTime: 2025-01-17 17:25:59
 * @FilePath: \yf_car\src\views\join.vue
-->
<!--  -->
<template>
  <div class="join">
    <i class="u-blue-bg"></i>
    <div class="g-white-box g-tips-box">
      <span v-if="!isLogin">
        <h3 class="u-tips-h3">无牌车辆请求入场</h3>
        <van-button type="primary" class="g-btn f-btncolor-blue" @click="clickLogin()">请授权登记</van-button>
      </span>
      <van-skeleton :row="1" :loading="loading" v-if="isLogin" :row-width="['100%']"> </van-skeleton>
      <img src="../assets/images/car-img.png" v-if="!loading" class="u-tips-car" />
      <span v-if="!loading">
        <h3 class="u-tips-h3">登记成功请入场!</h3>
        <p class="u-tips-p">出场时请用相同设备扫码</p>
        <p class="u-tips-p" v-if="lsCarId">
          临时车牌：
          <span> {{ lsCarId }}</span>
        </p>
      </span>
      <div v-if="!is_online">
        <keyboard :xnyCheck="true" :carNumAll.sync="carNum" />
        <div class="btn-sc" @click="generateCarNumber">生成临时车牌</div>
      </div>
    </div>

    <div class="offline-btn" v-if="!is_online && carNumLeng >= 7">
      <!-- <div class="offline-btn-tips">如果闸到未开放，请点击这里</div> -->
      <van-button type="primary" class="g-btn f-btncolor-blue" @click="openRamp"> <img class="laya-png" src="../assets/images/laya.png" />提交入场 </van-button>
    </div>
  </div>
</template>

<script>
import {removeObjNull, browserName, urlEncode, urlParse} from '@/utils/base'
import {setToken, getToken, removeToken, setPassagewayId, setAppid, getAppid} from '@/utils/auth.js'
import keyboard from '../components/keyboard'
import {scanEntryByNoPlate, getAppidData, getIsOnline, getCarOwnerHisTempCarNumber, getBluetoothUrl, join} from '@/api/base'
import {Toast, Button} from 'vant'
export default {
  components: {
    Toast,
    Button,
    keyboard,
  },
  data() {
    return {
      baseName: process.env.VUE_APP_BASE_API,
      formData: {
        passageway_id: '',
        from: browserName(),
        h5_url: encodeURIComponent(location.href),
      },
      carNum: '',
      isCar: true, //是否有车辆
      isLogin: false, //是否授权
      vxCode: '', //获取微信code
      zfbCode: '', //获取支付宝code
      vxCodeUrl: '', //获取微信code的URL
      zfbCodeUrl: '', //获取支付宝code的URL
      open_id: '', //微信授权后的open_id
      passageway_id: '', //通道ID
      scancodeshow: false, //是否显示微信和支付宝弹层，控制显示
      lsCarId: '', //临时车牌
      lsCarId1: '',
      btnIsUser: true, //授权按钮是否可用
      loading: true,
      btnText: '请授权登记',
      is_online: 1,
    }
  },
  computed: {
    carNumLeng() {
      var reg = /[\t\r\f\n\s]*/g
      return this.carNum.replace(reg, '').length
    },
  },
  methods: {
    clickLogin() {
      this.init()
    },
    clearStorge() {
      removeToken()
      this.init()
    },
    async init() {
      let name = browserName()
      const {passageway_id = ''} = urlParse()
      this.isNumFour = true
      this.key = 6
      let strName = ''
      if (passageway_id) {
        this.formData.passageway_id = passageway_id
        const {data} = await getAppidData({
          passageway_id: this.formData.passageway_id,
          from: name,
        })
        setPassagewayId(passageway_id)
        setAppid(data.app_id)
        if (getToken() && getToken() !== 'undefined') {
          this.isLogin = true
          this.isCar = true
          // this.$refs.footForm.phoneFn()
          this.getCarNum()
        } else {
          const {token, app_id = ''} = urlParse()
          if (token && app_id) {
            setToken(token, app_id)
            if (app_id === getAppid()) {
              this.isLogin = true
              this.isCar = true
              // this.$refs.footForm.phoneFn()
              this.getCarNum()
            } else {
              strName = urlEncode(removeObjNull(this.formData))
              if (name === 'others') {
                Toast('请用支付宝或者微信打开授权')
              } else {
                window.location.href = process.env.VUE_APP_BASE_API + 'v1.oauth/redirectOauth?' + strName
              }
            }
          } else {
            strName = urlEncode(removeObjNull(this.formData))
            if (name === 'others') {
              Toast('请用支付宝或者微信打开授权')
            } else {
              window.location.href = process.env.VUE_APP_BASE_API + 'v1.oauth/redirectOauth?' + strName
            }
          }
        }
      } else {
        Toast('通道信息错误')
      }
    },
    async getCarNum() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
      })
      this.loading = true
      let isOnline = (
        await getIsOnline({
          passageway_id: this.formData.passageway_id,
        })
      ).data
      this.is_online = isOnline.is_online
      // 判断设备是否在线
      if (this.is_online) {
        const {data, code, msg} = await scanEntryByNoPlate({
          passageway_id: this.formData.passageway_id,
        })
        this.loading = false
        if (code === 1) {
          Toast('加载成功')
          this.lsCarId = data.car_number
          this.lsCarId1 = data.car_owner_id
        } else {
          Toast(msg)
        }
      } else {
        this.loading = false
      }
    },
    async generateCarNumber() {
      try {
        this.loading = true
        Toast.loading({
          message: '生成中...',
          forbidClick: true,
          loadingType: 'spinner',
        })
        let carData = (await getCarOwnerHisTempCarNumber()).data
        this.lsCarId = carData.car_number
        this.carNum = carData.car_number
        Toast('生成成功')
      } catch (error) {
        this.loading = false
        Toast(error)
      } finally {
        this.loading = false
        // Toast.clear()
      }
    },
    async openRamp() {
      try {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner',
        })
        let carNum = this.carNum.replace(/[\t\r\f\n\s]*/g, '')
        let {code, msg} = await join({
          car_number: carNum,
          entry_passageway_id: this.formData.passageway_id,
        })

        if (code === 1) {
          const {data} = await getBluetoothUrl({
            passageway_id: this.formData.passageway_id,
            car_number: carNum,
          })
          location.href = data.url
        } else {
          Toast(msg)
        }
        Toast.clear()
      } catch (error) {
        Toast(error)
      }
    },
  },
  mounted() {
    // 初始化
    this.init()
  },
}
</script>
<style lang="scss" scoped>
.join {
  padding-top: 0.1px;
}
.g-tips-box {
  .van-skeleton__row {
    height: 200px !important;
  }
}
.offline-btn-tips {
  font-size: 0.24rem;
  text-align: center;
  margin: 0.3rem 0 -0.2rem 0;
  font-weight: bold;
}
.btn-sc {
  text-align: center;
  font-size: 0.24rem;
  margin-top: 0.1rem;
  color: #338dfc;
}
.laya-png {
  width: 0.3rem;
  height: 0.3rem;
  vertical-align: middle;
  margin-top: -2px;
}
</style>
