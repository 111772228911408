import request from '@/utils/request'

//扫码入场: 无牌车 车主 扫码入场
export function scanEntryByNoPlate(data) {
  return request({
    url: '/v1.orders/scanEntryByNoPlate',
    method: 'post',
    data,
  })
}

//扫描出场: 扫码 获取 出场停车订单
export function scanGetExportConsume(data) {
  return request({
    url: '/v1.orders/scanGetExportConsume',
    method: 'post',
    data,
  })
}

// 根据 车牌后几位 查询 园区内 在场车牌列表
export function getCarNumberListInStoping(data) {
  return request({
    url: '/v1.orders/getCarNumberListInStoping',
    method: 'post',
    data,
  })
}

// 根据 车牌号 获取 停车订单信息
export function getOrdersInfoByCarNumber(data) {
  return request({
    url: '/v1.orders/getOrdersInfoByCarNumber',
    method: 'post',
    data,
  })
}

// 根据 停车订单id 获取 停车订单信息
export function getConsumeInfo(data) {
  return request({
    url: '/v1.orders/getConsumeInfo',
    method: 'post',
    data,
  })
}

// 获取 停车订单的 在线支付信息
export function getPayInfo(data) {
  return request({
    url: '/v1.orders/getPayInfo',
    method: 'post',
    data,
  })
}

// 获取园区信息
export function getSmartparkId(data) {
  return request({
    url: '/v1.pub/getSmartparkId',
    method: 'post',
    data,
  })
}

// 获取园区appid
export function getAppidData(data) {
  return request({
    url: '/v1.pub/getAppId',
    method: 'post',
    data,
  })
}

// 获取场地名称
export function getLocalInfo(data) {
  return request({
    url: '/v1.pub/getLocalInfo',
    method: 'post',
    data,
  })
}

// 标记无牌车出场 /{{app_h5}}v1.orders/notCarNumberExport
export function notCarNumberExport(data) {
  return request({
    url: '/v1.orders/notCarNumberExport',
    method: 'post',
    data,
  })
}

// 临时车牌/{{app_h5}}v1.orders/notCarNumberExport
export function getCarOwnerHisTempCarNumber(data) {
  return request({
    url: '/v1.orders/getCarOwnerHisTempCarNumber',
    method: 'post',
    data,
  })
}

// 用户输入车牌领取公司券
export function carClaimCouponByCode(data) {
  return request({
    url: '/v1.orders/carClaimCouponByCode',
    method: 'post',
    data,
  })
}

// 静态二维码的领券接口
export function cancePay(data) {
  return request({
    url: '/v1.orders/cancePay',
    method: 'post',
    data,
  })
}

// 自动领券
export function claimCouponAutoByCode(data) {
  return request({
    url: '/v1.orders/claimCouponAutoByCode',
    method: 'post',
    data,
  })
}

// 获取优惠券的信息
export function getCompanyCouponPoolInfoByCode(data) {
  return request({
    url: '/v1.orders/getCompanyCouponPoolInfoByCode',
    method: 'post',
    data,
  })
}

// 落地页绑定车牌
export function carReimbursementRuleBindCarNumber(data) {
  return request({
    url: '/v1.orders/carReimbursementRuleBindCarNumber',
    method: 'post',
    data,
  })
}

// 获取蓝牙地址
export function getBluetoothUrl(data) {
  return request({
    url: '/v1.Stoping/getBluetoothUrl',
    method: 'post',
    data,
  })
}

// 获取通道是否在线
export function getIsOnline(data) {
  return request({
    url: '/v1.Stoping/getIsOnline',
    method: 'post',
    data,
  })
}

// 提交入场
export function join(data) {
  return request({
    url: '/v1.Stoping/join',
    method: 'post',
    data,
  })
}

// 计费
export function recordConsume(data) {
  return request({
    url: '/v1.Stoping/recordConsume',
    method: 'post',
    data,
  })
}

// 获取车辆的全部优惠券
export function carClaimCouponByCodeStatic(data) {
  return request({
    url: '/v1.orders/carClaimCouponByCodeStatic',
    method: 'post',
    data,
  })
}
