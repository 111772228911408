<!--
 * @Author: yanmin
 * @Description: 支付成功
 * @Date: 2022-04-14 11:16:03
 * @LastEditTime: 2024-12-25 16:29:00
 * @FilePath: \yf_car\src\views\leave.vue
-->
<!--  -->
<template>
  <div class="leave">
    <i class="u-blue-bg"></i>
    <div class="f-car-msg">
      <div v-if="!isordershow">
        <div class="g-white-box g-stop-payment f-have-carimg">
          <van-skeleton :row="1" :loading="loading" :row-width="['100%']"> </van-skeleton>
          <div v-if="!loading">
            <strong class="u-getinto-photo" v-if="!carImg.includes('car-img')">入场照片</strong>
            <!-- <strong class="u-getinto-photo" v-if="carImg != '../assets/images/car-img.png'">出场照片</strong> -->
            <img :src="carImg" class="u-getinto-img" @click="uploadShow" />
            <span class="m-carid-box">
              <i class="u-carid-icon"></i>
              <strong class="u-carid-id">{{ car_number }}</strong>
              <b class="u-carid-updata" v-if="isUp" @click="upMsg()"><i></i>刷新</b>
            </span>
            <dl class="m-money-box">
              <dt>停车费用</dt>
              <dd>￥<animate-number ref="reNum" :from="oldMoney" :to="money" :formatter="formatter" :key="money" duration="200"></animate-number></dd>
            </dl>
          </div>
        </div>
        <div class="g-white-box g-stop-introd">
          <van-skeleton :row="4" :loading="loading" :row-width="['100%', '100%', '100%', '100%']"> </van-skeleton>
          <ul class="g-list-two" v-if="!loading">
            <li>
              <strong>停车时长</strong>

              <b>
                {{ stopTime }}
              </b>
            </li>
            <li>
              <strong>入场时间</strong><b>{{ inTime }}</b>
            </li>
            <li>
              <strong>场地名称</strong
              ><b>
                <span v-if="fieldName.smartpark_name && fieldNameLen === 1">{{ fieldName.smartpark_name }} </span>
                <span v-if="fieldName.parkinglot_name">{{ fieldName.parkinglot_name }}</span>
                <span v-if="fieldName.parkinglot_name && fieldName.passageway_name"> - </span>
                <span v-if="fieldName.passageway_name">{{ fieldName.passageway_name }}</span>
              </b>
            </li>
            <li>
              <strong>已付金额</strong><b>¥{{ paymoney }}</b>
            </li>
            <li v-if="company_deduction_amount">
              <strong>企业代缴</strong><b>¥{{ company_deduction_amount }}</b>
            </li>
            <li @click="seletctCoupon" v-if="remote_file_list.length > 0">
              <strong>优惠券</strong
              ><b class="red-b">
                <span v-if="coupon_title"> ¥{{ preferential_money }}({{ coupon_title }}) </span>
                <span v-else style="color: #dd8406">{{ remote_file_list.length }}张可用</span>
              </b>
              <span><van-icon name="arrow" /></span>
            </li>
          </ul>
        </div>

        <!-- :class="money == 0 ? 'f-btncolor-gay' : ''" -->
        <!-- <van-button class="g-btn f-btncolor-blue" @click="carPay()">
          车牌支付
        </van-button> -->
        <!-- <van-button
          class="g-btn f-btncolor-blue"
          @click="noCarPay()"
        >
         无牌车支付
        </van-button> -->
        <van-skeleton :row="1" :loading="loading" :row-width="['100%']"> </van-skeleton>
      </div>
      <van-skeleton :row="2" :loading="loading" :row-width="['100%', '100%']"> </van-skeleton>
      <div style="margin-top: 0.8rem" v-if="!loading">
        <van-button class="g-btn f-btncolor-blue" :disabled="price == 0 || isPay" @click="clickPay()">
          {{ price == 0 ? '无需支付' : '确认支付' }}
        </van-button>
        <van-button class="g-btn f-btncolor-blue" v-if="price == 0 && is_online === 0" @click="openRamp()"
          ><img class="laya-png" src="../assets/images/laya.png" /> 点击出场
        </van-button>
      </div>
      <div class="g-foot-box" style="margin: 0" v-if="!loading">
        <van-button class="g-btn" @click="jumpCar"> 车牌有误 </van-button>
        <!-- <p class="u-updata-tips">
          若车牌不是您的，请尝试<span @click="upMsg()" class="up_msg"> 刷新 </span>或<span class="up_msg" @click="jumpCar"> 输入车牌</span>
        </p> -->
        <!-- <footdiv ref="footForm"  :passageway-id="passageway_id"></footdiv> -->
      </div>
      <!-- loading层 -->
      <div class="g-loading-box" v-if="isloading">
        <van-loading size="24px" class="v-loading-box" color="#0094ff" v-show="isloading">加载中...</van-loading>
        <van-button type="primary" class="g-btn f-btncolor-blue" style="margin-top: 5rem" @click="initData" v-if="!isLogin">请授权登记</van-button>
      </div>

      <!-- 扫码提示 -->
      <scancode :scancodeshow="scancodeshow"></scancode>
    </div>
    <section class="imgzoom_pack">
      <div class="imgzoom_x">X</div>
      <div class="imgzoom_img"><img src="" /></div>
    </section>
    <van-popup v-model="searchCaridShow" position="bottom" round lock-scroll lazy-render class="g-showcar-box">
      <div style="width: 100%; position: relative">
        <div class="ul-select">
          <van-checkbox-group v-model="result">
            <div class="coupons-box">
              <div class="coupons-box-item" v-for="(item, index) in remote_file_list" :key="item.id" @click="seletedCoupon(item)">
                <div class="coupons-box-item-lf">
                  <div class="type">
                    <div class="type-item" style="font-weight: bold">
                      {{
                        item.deduction_type == 1
                          ? item.discount_percent
                            ? item.discount_percent * 10
                            : 0
                          : item.deduction_type == 0
                          ? item.preferential_amount
                            ? item.preferential_amount
                            : 0
                          : item.deduction_type == 2
                          ? '全免'
                          : item.value_preview
                      }}
                      {{ item.deduction_type == 1 ? '折' : item.deduction_type == 0 ? '元' : '' }}
                    </div>
                    <div class="type-item use_rule" v-if="item.use_rule && item.use_rule.min_orders_amount > 0">
                      满{{ item.use_rule ? item.use_rule.min_orders_amount : 0 }}元使用
                    </div>
                  </div>
                </div>
                <div class="coupons-box-item-rt">
                  <div style="width: 100%">
                    <div class="ct-title">
                      {{ item.title }}
                      <van-checkbox :name="item.id"></van-checkbox>
                    </div>
                    <div class="ct-item">
                      <span></span>
                      {{ item.end_time ? parseTime(item.start_time, '{y}-{m}-{d}') + ' ~ ' + parseTime(item.end_time, '{y}-{m}-{d}') : '永久有效' }}
                    </div>
                    <div class="ct-item">
                      <span></span>
                      {{ item.remarks }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-checkbox-group>
        </div>
        <div class="btns">
          <div class="btns-price">
            -￥<animate-number ref="allnum" :from="changeValue" :to="newValue" :formatter="formatter" :key="newValue" duration="200"></animate-number>
          </div>
          <van-button round type="info" block @click="searchCaridShow = false">确定</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import wx from 'weixin-jsapi'
import '@/assets/style/keyboard.css'
import {removeObjNull, browserName, urlEncode, parseTime, urlParse} from '@/utils/base.js'
import {setToken, getToken, setPassagewayId, setAppid, getAppid, setSmarkId} from '@/utils/auth'
import {getCarCouponListOnOrders} from '@/api/index'
import {
  scanGetExportConsume,
  getOrdersInfoByCarNumber,
  getConsumeInfo,
  getPayInfo,
  getSmartparkId,
  getAppidData,
  notCarNumberExport,
  cancePay,
  getBluetoothUrl,
  getIsOnline,
  getCarOwnerHisTempCarNumber,
} from '@/api/base'
import {Toast, ImagePreview, Skeleton} from 'vant'
export default {
  components: {
    Toast,
    ImagePreview,
    Skeleton,
  },
  data() {
    return {
      passageway_id: '', //通道ID
      carImg: require('../assets/images/car-img.png'), //车辆照片
      carBigImg: './images/car-img.png', //车辆大图片
      consume_id: '',
      car_number: '', //车牌号
      money: 0, //缴费金额
      fieldNameLen: 0,
      isLogin: false,
      isPay: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      loading: true,
      result: [],
      searchCaridShow: false,
      coupon_title: '',
      remote_file_list: [],
      fieldName: {
        smartpark_name: '',
        parkinglot_name: '',
        passageway_name: '',
      }, //场地名称
      exportObj: '', //出口
      inTime: '', //入场时间
      stopTime: '', //停车时长
      paymoney: '', //已付金额
      id: '', //订单号
      smartpark_id: '', //园区ID
      isUp: true, //是否显示刷新
      vxCode: '',
      vxCodeUrl: '', //获取微信code的URL
      zfbCode: '',
      zfbCodeUrl: '', //获取支付宝code的URL
      open_id: '', //微信授权后的open_id
      isordershow: true, //是否显示车牌错误
      npaymentfont: '', //提示语searchCaridList
      isloading: true, //是否显示loading
      scancodeshow: false, //非微信和支付宝弹层，控制显示
      payBtnText: '确认支付', //支付按钮
      baseName: process.env.VUE_APP_BASE_API,
      preferential_money: '',
      company_deduction_amount: '',
      isShowDiscount: false,
      passageway_id: '',
      formData: {
        h5_url: encodeURIComponent(location.href),
        passageway_id: '',
        consume_id: '',
        smartpark_id: '',
        from: browserName(),
      },
      price: '',
      parseTime: parseTime,
      searchCaridList: [],
      currentId: '',
      flag: true,
      changeValue: 0,
      newValue: 0,
      oldMoney: 0,
      is_online: 0,
    }
  },
  computed: {},
  watch: {
    preferential_money(newValue, oldValue) {
      // console.log(44, oldValue, newValue)
      this.changeValue = oldValue
      this.newValue = newValue
      // console.log(44, this.changeValue)
    },
    money(newValue, oldValue) {
      this.oldMoney = oldValue
    },
  },
  methods: {
    formatter: function (num) {
      return num.toFixed(2) //小数点后几位，数字就是几小数点后几位
    },
    async clickPay() {
      this.isPay = true
      let payway = browserName() === 'wx' ? 'wxpay' : browserName() === 'ali' ? 'alipay' : browserName()
      let payType = payway === 'wxpay' ? 'mp' : payway === 'alipay' ? 'wap' : 'others'
      if (payway !== 'others') {
        try {
          Toast.loading({
            message: '加载中.',
            forbidClick: true,
            loadingType: 'spinner',
          })
          const {data, code, msg} = await getPayInfo({
            consume_id: this.id,
            payway: payway,
            pay_type: payType,
            coupon_id: this.result[0],
          })
          if (code === 1) {
            if (data === true) {
              this.$router.push({
                path: '/pay',
                query: {
                  car_number: this.car_number,
                  smartpark_id: this.smartpark_id,
                  passageway_id: this.passageway_id,
                },
              })
            } else {
              if (payway === 'wxpay') {
                this.isPay = true
                this.jsSDK(data.mp)
              } else {
                Toast.clear()
                const div = document.createElement('div')
                /* 此处form就是后台返回接收到的数据 */
                div.innerHTML = data.wap
                document.body.appendChild(div)
                document.forms[0].submit()
                this.isPay = false
                // window.location.href = data
              }
            }
          } else {
            Toast(msg)
            this.isPay = false
          }
        } catch (e) {
          this.isPay = false
          Toast(e)
        }
      } else {
        this.isPay = false
      }
    },
    filterRules(data) {
      let res = ''
      Object.keys(data).map(ele => {
        res += data[ele][0] + ','
      })
      return res.substring(0, res.length - 1)
    },
    seletedCoupon(item) {
      this.flag = !this.flag
      if (this.currentId !== item.id) {
        this.result = [item.id]
        this.coupon_title = item.title
        this.flag = true
      } else {
        this.result = this.flag ? [item.id] : []
        this.coupon_title = this.flag ? item.title : ''
      }
      this.currentId = item.id
      if (this.result.length) {
        Toast.loading({
          message: '选择中...',
          forbidClick: true,
          loadingType: 'spinner',
        })
        getConsumeInfo({
          consume_id: this.id,
          coupon_id: this.result[0],
        }).then(res => {
          Toast.clear()
          this.money = res.data.trade_price
          this.price = res.data.price
          this.company_deduction_amount = res.data.company_deduction_amount
          this.preferential_money = res.data.preferential_money
        })
      } else {
        getConsumeInfo({
          consume_id: this.id,
          // coupon_id: this.result[0]
        }).then(res => {
          Toast.clear()
          this.money = res.data.trade_price
          this.price = res.data.price
          this.company_deduction_amount = res.data.company_deduction_amount
          this.preferential_money = 0
        })
      }
    },
    // 跳转微信
    async openRamp() {
      // 通用跳转方法
      // wx.miniProgram.navigateTo({url: '/packageA/pages/doorqr/doorqr'})
      const {data} = await getBluetoothUrl({
        passageway_id: this.passageway_id,
        car_number: this.car_number,
      })
      location.href = data.url
      // location.href = data.url
    },
    initData() {
      this.init()
    },
    // 车牌支付
    carPay(passageway_id) {
      this.$router.push({
        path: '/prepay',
        query: {
          smartpark_id: this.smartpark_id,
          passageway_id: passageway_id,
        },
      })
    },
    noCarPay() {
      let passageway_id = this.$route.query.passageway_id || this.passageway_id
      let car_number = this.$route.query.car_number || this.car_number
      if (passageway_id || car_number) {
        notCarNumberExport({
          passageway_id: passageway_id,
          car_number: car_number,
        }).then(res => {
          if (res.code === 1) {
            this.upMsg()
          }
        })
      }
    },
    // 优惠券默认选中
    setFirst() {
      getCarCouponListOnOrders({
        consume_id: this.id,
      }).then(res => {
        this.remote_file_list = Object.keys(res.data).length ? res.data.filter(ele => ele.is_can_use) : []
        let id = this.remote_file_list[0]?.id ?? ''
        this.result = [id]
        this.currentId = id
        this.coupon_title = this.remote_file_list[0]?.title ?? ''
        getConsumeInfo({
          consume_id: this.id,
          coupon_id: this.result[0],
        }).then(resd => {
          // Toast.clear()
          this.money = resd.data.trade_price
          this.price = resd.data.price
          this.company_deduction_amount = resd.data.company_deduction_amount
          this.preferential_money = resd.data.preferential_money
        })
      })
    },
    // 优惠券选择
    seletctCoupon() {
      this.searchCaridShow = true
    },
    jumpCar() {
      this.carPay(this.$route.query.passageway_id)
    },
    setleng() {
      // this.fieldName.filters(ele)
      let index = 0
      Object.keys(this.fieldName).map(ele => {
        if (this.fieldName[ele]) {
          index++
        }
      })
      return index
    },
    upMsg() {
      if (this.$route.query.smartpark_id && this.$route.query.car_number) {
        setSmarkId(this.$route.query.smartpark_id)
        // this.$refs.footForm.phoneFn();
        this.getOrderCarNum()
      } else {
        const {passageway_id = '', consume_id = ''} = urlParse()
        this.getOrder(passageway_id, consume_id)
      }
    },
    uploadShow() {
      if (this.carImg && !this.carImg.includes('/img/car-img')) {
        ImagePreview([this.carImg])
      }
    },
    //微信调起支付
    jsSDK(data) {
      let _this = this
      Toast.clear()
      wx.config({
        debug: false, //这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
        appId: data.appId,
        timestamp: data.timestamp,
        nonceStr: data.noncestr,
        signature: data.signature,
        jsApiList: ['chooseWXPay'],
      })
      wx.ready(function () {
        let that = _this
        wx.chooseWXPay({
          appId: data.appId,
          timestamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: data.nonceStr, // 支付签名随机串，不长于 32
          package: data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: data.paySign, // 支付签名
          success: function (res) {
            that.$router.push({
              path: '/pay',
              query: {
                car_number: that.car_number,
                smartpark_id: that.smartpark_id,
                passageway_id: that.passageway_id,
              },
            })
            // if (that.is_online) {
            //   that.$router.push({
            //     path: '/pay',
            //     query: {
            //       car_number: that.car_number,
            //       smartpark_id: that.smartpark_id,
            //     },
            //   })
            // } else {
            //   that.openRamp()
            // }
            //跳转到支付成功页面有这个页面
            _this.isPay = false
            // console.log(res);
          },
          cancel: function (res) {
            //提示引用的是mint-UI里toast
            _this.isPay = false
            _this.flag = true
            _this.cancelData()
            Toast('已取消支付')
          },
          fail: function (res) {
            _this.isPay = false
            Toast('支付失败，请重试')
          },
        })
      })
    },
    async getOrderCarNum() {
      // Toast.loading({
      //     message: '加载中...',
      //     forbidClick: true,
      //     loadingType: 'spinner'
      // });
      this.isordershow = false
      this.isloading = true
      this.loading = true
      const {
        data: orderList,
        code,
        msg,
      } = await getOrdersInfoByCarNumber({
        smartpark_id: this.$route.query.smartpark_id,
        car_number: this.$route.query.car_number,
        passageway_id: this.$route.query.passageway_id,
      })
      this.passageway_id = this.$route.query.passageway_id
      this.is_online = (await getIsOnline({passageway_id: this.passageway_id})).data.is_online
      this.smartpark_id = orderList.smartpark_id
      this.isloading = false
      if (code === 1) {
        this.isordershow = false
        this.loading = false
        // let orderList = await getConsumeInfo({
        //   consume_id: data.id,
        // });
        // const { data: localInfos } = await getLocalInfo({
        //   smartpark_id: this.$route.query.smartpark_id,
        //   consume_id: orderList.id,
        // });
        this.loading = false
        if (Object.keys(orderList).length) {
          // Toast("加载成功");
          // console.log(333, orderList)
          this.car_number = orderList.car_number
          this.money = orderList.trade_price
          this.price = orderList.price
          this.passageway_id = orderList.export_passageway_id

          // if (this.money == 0) {
          //   this.payBtnText = '无需支付'
          // }
          //判断图片是否404
          // console.log(orderList.data.big_img)
          // this.carImg =
          if (
            typeof orderList.img != 'undefined' &&
            orderList.img != 'undefined' &&
            typeof orderList.img != 'null' &&
            orderList.img != 'null' &&
            orderList.img != null
          ) {
            let carImg = new Image()
            carImg.src = orderList.big_img
            carImg.onload = () => {
              //图片不是404
              // this.carImg = orderList.data.img; //车辆缩略图
              this.carImg = orderList.big_img //车辆大图
              // console.log("img is loaded")
            }
            carImg.onerror = function () {
              //图片是404
              // console.log("error!")
            }
          }
          // this.fieldName = orderList.data.smartpark_info.name
          this.setName(orderList)
          this.inTime = parseTime(orderList.entry_time)
          this.stopTime = orderList.duration_desp.replace(/\d+秒/, '')
          let yfje = orderList.payed_money
          this.coupon_title = ''
          this.preferential_money = 0
          // this.preferential_money = orderList.preferential_money;
          if (typeof yfje != 'undefined' && yfje != 'undefined' && yfje != null) {
            this.paymoney = yfje
          } else {
            this.paymoney = '0.00'
          }
          this.id = orderList.id
          this.company_deduction_amount = orderList.company_deduction_amount
          this.flag = true
          this.setFirst()
        } else {
          // this.isordershow = true
          Toast(`无该车牌订单`)
          this.carPay(this.$route.query.passageway_id)
        }

        // this.smartpark_id = orderList.data.smartpark_id;
        // this.lsCarId = data.car_number
        // this.lsCarId1 = data.car_owner_id
      } else {
        // this.isordershow = true;

        this.carPay(this.$route.query.passageway_id)
        // Toast(msg)
      }
    },
    // 初始化
    async init() {
      const {passageway_id = '', consume_id = ''} = urlParse()
      let name = browserName()
      let strName = ''
      this.isLogin = false
      if (this.$route.query.smartpark_id && this.$route.query.car_number) {
        setSmarkId(this.$route.query.smartpark_id)
        this.isLogin = true
        // this.$refs.footForm.phoneFn();
        this.getOrderCarNum()
      } else {
        if (!passageway_id && !consume_id) {
          Toast('通道信息错误')
        } else {
          if (passageway_id && !consume_id) {
            this.formData.passageway_id = passageway_id
            this.passageway_id = passageway_id
            const {data} = await getAppidData({
              passageway_id: this.formData.passageway_id,
              from: name,
            })
            setPassagewayId(passageway_id)
            setAppid(data.app_id)
            if (getToken() && getToken() !== 'undefined') {
              // this.$refs.footForm.phoneFn();
              this.isLogin = true
              this.is_online = (await getIsOnline({passageway_id: this.passageway_id})).data.is_online
              this.getOrder(passageway_id, false)
            } else {
              const {token, app_id = ''} = urlParse()
              if (token && app_id) {
                setToken(token, app_id)
                if (app_id === getAppid()) {
                  this.isLogin = true
                  // this.$refs.footForm.phoneFn();
                  this.getOrder(passageway_id, false)
                } else {
                  strName = urlEncode(removeObjNull(this.formData))
                  if (name == 'others') {
                    Toast('请用支付宝或者微信打开授权')
                  } else {
                    // alert(111, process.env.VUE_APP_BASE_API + 'v1.oauth/redirectOauth?' + strName)
                    window.location.href = process.env.VUE_APP_BASE_API + 'v1.oauth/redirectOauth?' + strName
                  }
                }
              } else {
                strName = urlEncode(removeObjNull(this.formData))
                if (name == 'others') {
                  Toast('请用支付宝或者微信打开授权')
                } else {
                  // alert(111, process.env.VUE_APP_BASE_API + 'v1.oauth/redirectOauth?' + strName)
                  window.location.href = process.env.VUE_APP_BASE_API + 'v1.oauth/redirectOauth?' + strName
                }
              }
            }
          } else if (consume_id && passageway_id) {
            this.formData.passageway_id = passageway_id
            this.passageway_id = passageway_id
            this.formData.consume_id = consume_id
            this.is_online = (await getIsOnline({passageway_id: this.passageway_id})).data.is_online
            const {data} = await getAppidData({
              passageway_id: this.formData.passageway_id,
              from: name,
            })
            setPassagewayId(passageway_id)
            setAppid(data.app_id)
            if (getToken() && getToken() !== 'undefined') {
              this.isLogin = true
              // this.$refs.footForm.phoneFn();
              this.getOrder(passageway_id, consume_id)
            } else {
              const {token, app_id = ''} = urlParse()
              if (token && app_id) {
                setToken(token, app_id)
                if (app_id === getAppid()) {
                  this.isLogin = true
                  // this.$refs.footForm.phoneFn();
                  this.getOrder(passageway_id, consume_id)
                } else {
                  strName = urlEncode(removeObjNull(this.formData))
                  if (name == 'others') {
                    Toast('请用支付宝或者微信打开授权')
                  } else {
                    // alert(111, process.env.VUE_APP_BASE_API + 'v1.oauth/redirectOauth?' + strName)
                    window.location.href = process.env.VUE_APP_BASE_API + 'v1.oauth/redirectOauth?' + strName
                  }
                }
              } else {
                strName = urlEncode(removeObjNull(this.formData))
                if (name == 'others') {
                  Toast('请用支付宝或者微信打开授权')
                } else {
                  // alert(111, process.env.VUE_APP_BASE_API + 'v1.oauth/redirectOauth?' + strName)
                  window.location.href = process.env.VUE_APP_BASE_API + 'v1.oauth/redirectOauth?' + strName
                }
              }
            }
          } else {
            this.formData.consume_id = consume_id
            const {data} = await getAppidData({
              consume_id: consume_id,
              from: name,
            })
            setAppid(data.app_id)
            if (getToken() && getToken() !== 'undefined') {
              this.isLogin = true
              // this.$refs.footForm.consume_id = consume_id;
              // this.$refs.footForm.phoneFn();
              this.getOrder(false, consume_id)
            } else {
              const {token, app_id = ''} = urlParse()
              if (token && app_id) {
                setToken(token, app_id)
                if (app_id === getAppid()) {
                  this.isLogin = true
                  // this.$refs.footForm.consume_id = consume_id;
                  // this.$refs.footForm.phoneFn();
                  this.getOrder(false, consume_id)
                } else {
                  strName = urlEncode(removeObjNull(this.formData))
                  // console.log(strName);
                  if (name == 'others') {
                    Toast('请用支付宝或者微信打开授权')
                  } else {
                    // alert(111, process.env.VUE_APP_BASE_API + 'v1.oauth/redirectOauth?' + strName)
                    window.location.href = process.env.VUE_APP_BASE_API + 'v1.oauth/redirectOauth?' + strName
                  }
                }
              } else {
                strName = urlEncode(removeObjNull(this.formData))
                if (name == 'others') {
                  Toast('请用支付宝或者微信打开授权')
                } else {
                  // alert(111, process.env.VUE_APP_BASE_API + 'v1.oauth/redirectOauth?' + strName)
                  window.location.href = process.env.VUE_APP_BASE_API + 'v1.oauth/redirectOauth?' + strName
                }
              }
            }
          }
        }
      }
    },
    setName(data) {
      // console.log(33, data)
      const {
        smartpark_name,
        parkinglot_info: {name: parkinglot_name},
        export_passageway_name: passageway_name,
      } = data
      this.fieldName = {smartpark_name, parkinglot_name, passageway_name}
      this.fieldNameLen = this.setleng()
    },
    cancelData() {
      cancePay({
        consume_id: this.id,
      }).then(res => {
        // this.money = res.data.trade_price;
        // this.price = res.data.price;
        // this.company_deduction_amount = res.data.company_deduction_amount
        this.setFirst()
      })
    },
    async getOrder(passageway_id, consume_id) {
      this.isordershow = false
      this.isloading = true
      this.loading = true
      if (consume_id) {
        let orderList = await getConsumeInfo({
          consume_id: consume_id,
        })
        this.isloading = false
        // Toast("加载成功");
        this.loading = false
        if (orderList.code === 1) {
          this.isordershow = false
          this.car_number = orderList.data.car_number
          this.money = orderList.data.trade_price
          this.price = orderList.data.price
          this.smartpark_id = orderList.data.smartpark_id
          this.passageway_id = orderList.export_passageway_id
          this.is_online = (await getIsOnline({passageway_id: this.passageway_id})).data.is_online
          if (
            typeof orderList.data.img != 'undefined' &&
            orderList.data.img != 'undefined' &&
            typeof orderList.data.img != 'null' &&
            orderList.data.img != 'null' &&
            orderList.data.img != null
          ) {
            let carImg = new Image()
            carImg.src = orderList.data.big_img
            carImg.onload = () => {
              //图片不是404
              // this.carImg = orderList.data.img; //车辆缩略图
              this.carImg = orderList.data.big_img //车辆大图setFirst
              // console.log("img is loaded")
            }
            carImg.onerror = function () {
              //图片是404
              // console.log("error!")
            }
          }
          this.setName(orderList.data)
          this.inTime = parseTime(orderList.data.entry_time)
          this.stopTime = orderList.data.duration_desp
          let yfje = orderList.data.payed_money
          this.preferential_money = 0
          // this.preferential_money = orderList.preferential_money;
          this.coupon_title = ''
          if (typeof yfje != 'undefined' && yfje != 'undefined' && yfje != null) {
            this.paymoney = yfje
          } else {
            this.paymoney = '0.00'
          }
          // this.
          this.id = consume_id
          this.company_deduction_amount = orderList.company_deduction_amount
          this.flag = true
          this.setFirst()
          // this.smartpark_id = orderList.data.smartpark_id;
        } else {
          // this.isordershow = true;
          const {data: smartpark} = await getSmartparkId({
            consume_id: consume_id,
          })
          this.smartpark_id = smartpark.smartpark_id
          this.carPay(this.$route.query.passageway_id)
        }
      } else {
        if (passageway_id && !consume_id) {
          if (this.is_online) {
            const {
              data: orderList,
              code,
              msg,
            } = await scanGetExportConsume({
              passageway_id: passageway_id,
            })
            this.smartpark_id = orderList.smartpark_id

            this.isloading = false
            if (code === 1) {
              this.isordershow = false
              this.loading = false
              // Toast("加载成功");
              this.car_number = orderList.car_number
              this.money = orderList.trade_price
              this.price = orderList.price
              //判断图片是否404
              if (
                typeof orderList.img != 'undefined' &&
                orderList.img != 'undefined' &&
                typeof orderList.img != 'null' &&
                orderList.img != 'null' &&
                orderList.img != null
              ) {
                let carImg = new Image()
                carImg.src = orderList.big_img
                carImg.onload = () => {
                  //图片不是404
                  // this.carImg = orderList.data.img; //车辆缩略图
                  this.carImg = orderList.big_img //车辆大图
                  // console.log("img is loaded")
                }
                carImg.onerror = function () {
                  //图片是404
                  // console.log("error!")
                }
              }
              // this.fieldName = orderList.data.smartpark_info.name
              this.setName(orderList)
              this.inTime = parseTime(orderList.entry_time)
              this.stopTime = orderList.duration_desp
              let yfje = orderList.payed_money
              this.preferential_money = 0
              // this.preferential_money = orderList.preferential_money;
              this.coupon_title = ''
              if (typeof yfje != 'undefined' && yfje != 'undefined' && yfje != null) {
                this.paymoney = yfje
              } else {
                this.paymoney = '0.00'
              }
              this.id = orderList.id
              // this.smartpark_id = orderList.data.smartpark_id;
              this.company_deduction_amount = orderList.company_deduction_amount
              this.flag = true
              this.setFirst()
            } else {
              const {data: smartpark} = await getSmartparkId({
                passageway_id: passageway_id,
              })
              this.smartpark_id = smartpark.smartpark_id
              Toast(`未查询到车牌`)
              this.carPay(passageway_id)
            }
          } else {
            let carData = await getCarOwnerHisTempCarNumber()
            // console.log(11, carData)
            const {data: smartpark} = await getSmartparkId({
              passageway_id: passageway_id,
            })
            this.smartpark_id = smartpark.smartpark_id
            let orderList = (
              await getOrdersInfoByCarNumber({
                car_number: carData.data.car_number,
                passageway_id: passageway_id,
                smartpark_id: this.smartpark_id,
              })
            ).data
            if (Object.keys(orderList).length) {
              // Toast("加载成功");
              // console.log(333, orderList)
              this.isloading = false
              this.isordershow = false
              this.loading = false
              this.car_number = orderList.car_number
              this.money = orderList.trade_price
              this.price = orderList.price
              this.passageway_id = orderList.export_passageway_id

              // if (this.money == 0) {
              //   this.payBtnText = '无需支付'
              // }
              //判断图片是否404
              // console.log(orderList.data.big_img)
              // this.carImg =
              if (
                typeof orderList.img != 'undefined' &&
                orderList.img != 'undefined' &&
                typeof orderList.img != 'null' &&
                orderList.img != 'null' &&
                orderList.img != null
              ) {
                let carImg = new Image()
                carImg.src = orderList.big_img
                carImg.onload = () => {
                  //图片不是404
                  // this.carImg = orderList.data.img; //车辆缩略图
                  this.carImg = orderList.big_img //车辆大图
                  // console.log("img is loaded")
                }
                carImg.onerror = function () {
                  //图片是404
                  // console.log("error!")
                }
              }
              // this.fieldName = orderList.data.smartpark_info.name
              this.setName(orderList)
              this.inTime = parseTime(orderList.entry_time)
              this.stopTime = orderList.duration_desp.replace(/\d+秒/, '')
              let yfje = orderList.payed_money
              this.coupon_title = ''
              this.preferential_money = 0
              // this.preferential_money = orderList.preferential_money;
              if (typeof yfje != 'undefined' && yfje != 'undefined' && yfje != null) {
                this.paymoney = yfje
              } else {
                this.paymoney = '0.00'
              }
              this.id = orderList.id
              this.company_deduction_amount = orderList.company_deduction_amount
              this.flag = true
              this.setFirst()
            } else {
              // this.isordershow = true

              Toast(`无该车牌订单`)
              this.carPay(this.$route.query.passageway_id)
            }
            // console.log(44, orderInfo)
            // if (Object.keys(orderInfo.length) > 0) {
            // } else {
            // }
          }
        }
      }
    },
  },
  created() {},
  mounted() {
    this.init()
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
}
</script>
<style lang="scss" scoped>
.leave {
  padding-top: 0.1px;
}
.up_msg {
  color: #1d72e8;
}
.g-stop-payment {
  .van-skeleton__row {
    height: 200px !important;
  }
}
.g-stop-introd {
  .van-skeleton__row {
    height: 30px !important;
  }
}
.g-showcar-box {
  // padding: 47px;
  background: #fff;
  .ul-select {
    margin-top: 0.2rem;
    margin-bottom: 0.1rem;
    max-height: 50vh;
    overflow-y: auto;
    padding: 0 0.4rem;
    .coupons-box {
      // padding: 0.2rem;
      background: #fff;
      &-item {
        width: 100%;
        height: 1.8rem;
        background: url('~@/assets/images/coupons.png') no-repeat center center;
        margin-bottom: 0.1rem;
        background-size: 100% 100%;
        display: flex;
        &-lf {
          width: 1.5rem;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 0.3rem;
          .type-item {
            text-align: center;
          }
          .use_rule {
            font-size: 0.2rem;
            margin-top: 0.2rem;
          }
        }
        &-rt {
          flex: 1;
          // padding: 0.2rem;
          display: flex;
          align-items: center;
          padding: 0 0.2rem;
          .ct-title {
            color: #000;
            font-size: 0.28rem;
            font-weight: bold;
            margin-bottom: 0.1rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            &-qr {
              color: #409eff;
            }
          }
          .ct-item {
            color: #bab5b4;
            font-size: 0.24rem;
            line-height: 0.28rem;
            margin-bottom: 0.1rem;
          }
        }
      }
    }
    .van-cell-group {
      background: #f5f5f5;
    }
    .van-cell {
      border: 1px solid #ebedf0;
      border-radius: 5px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      margin-bottom: 5px;
      padding: 0.1rem 0.1rem;
      height: 1.7rem;
      .box {
        display: flex;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
        // height: 1.7rem;
        .box-left {
          flex: 1;
          padding: 0 0.1rem;
          .box-left-item {
            display: flex;
            justify-content: space-between;
            padding-left: 0.1rem;
          }
          .box-left-time {
            padding-left: 0.1rem;
            font-size: 0.2rem;
            color: #666;
            line-height: 0.3rem;
            span {
              margin-right: 0.1rem;
            }
          }
        }
        .box-rt {
          width: 1.5rem;
          color: #fff;
          font-size: 0.2rem;
          text-align: center;
          padding-top: 0.2rem;
          .price {
            font-size: 0.3rem;
          }
        }
      }
    }
    .van-cell__value {
      // display: none;
    }
    .prompt-info {
      position: absolute;
      width: 100%;
      bottom: -1rem;
      font-size: 0.23rem;
      line-height: 0.5rem;
      text-align: center;
      background-image: -webkit-linear-gradient(bottom, #cfd9df 0%, #e2ebf0 100%);
      background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
    }
  }
  .btns {
    margin: 0.4rem 0.4rem;
    margin-top: 0;
    &-price {
      text-align: center;
      color: red;
      line-height: 0.4rem;
      font-size: 0.3rem;
      margin-bottom: 0.1rem;
    }
  }
}
.g-list-two li {
  padding: 0.05rem 0;
  height: 0.4rem;
  line-height: 0.4rem;
}
.laya-png {
  width: 0.3rem;
  height: 0.3rem;
  vertical-align: middle;
  margin-top: -2px;
}
</style>
